import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/FaqPage/Banner/Banner";
import Faqs from "../components/FaqPage/Faqs/Faqs";

const FaqPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Rudiq · FAQs</title>
        <meta name="title" content="Rudiq FAQs | Your Questions Answered" />
        <meta property="og:title" content="Rudiq FAQs | Your Questions Answered" />
        <meta property="twitter:title" content="Rudiq FAQs | Your Questions Answered" />

        <meta name="description" content="Find answers to frequently asked questions about Rudiq’s marketing services, process, and more. Get the information you need to make informed decisions." />
        <meta property="og:description" content="Find answers to frequently asked questions about Rudiq’s marketing services, process, and more. Get the information you need to make informed decisions." />
        <meta property="twitter:description" content="Find answers to frequently asked questions about Rudiq’s marketing services, process, and more. Get the information you need to make informed decisions." />

        <meta property="og:image" content="preview.webp" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.webp" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Faqs />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default FaqPage;
