import React, { useState } from "react";
import classes from './Faqs.module.scss';
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

const cards = [
    {
        title: 'faq.faq_01.title',
        text: 'faq.faq_01.text',
    },
    {
        title: 'faq.faq_02.title',
        text: 'faq.faq_02.text',
    },
    {
        title: 'faq.faq_03.title',
        text: 'faq.faq_03.text',
    },
    {
        title: 'faq.faq_04.title',
        text: 'faq.faq_04.text',
    },
    {
        title: 'faq.faq_05.title',
        text: 'faq.faq_05.text',
    },
    {
        title: 'faq.faq_06.title',
        text: 'faq.faq_06.text',
    },
    {
        title: 'faq.faq_07.title',
        text: 'faq.faq_07.text',
    },
    {
        title: 'faq.faq_08.title',
        text: 'faq.faq_08.text',
    },
    {
        title: 'faq.faq_09.title',
        text: 'faq.faq_09.text',
    },
    {
        title: 'faq.faq_10.title',
        text: 'faq.faq_10.text',
    },
];

const Faqs = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);

    const cardClick = (value) => value === active
        ? setActive(-1)
        : setActive(value);

    return (
        <section className="container">
            <div className={classes.wrap}>
                {cards.map((card, index) =>
                    <Fade key={index} bottom>
                        <div className={`${classes.card} ${active === index ? classes.active : ''}`}>
                            <div className={classes.head}>
                                <div className={classes.num}>
                                    {index < 9
                                        ? <>0{index + 1}</>
                                        : <>{index + 1}</>
                                    }
                                </div>
                                <h4 className={`${classes.subtitle} font-24-roboto`}>
                                    {t(card.title)}
                                </h4>
                                <div
                                    className={classes.button}
                                    onClick={() => cardClick(index)}
                                ></div>
                            </div>
                            <p className={`${classes.content} font-16`}>
                                {t(card.text)}
                            </p>
                        </div>
                    </Fade>
                )}
            </div>
        </section>
    );
};

export default Faqs;
