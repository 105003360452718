import React from "react";
import classes from './WhyChoose.module.scss';
import { useTranslation } from "react-i18next";
import image_02 from '../../../assets/img/HomePage/WhyChoose/image_02.webp';
import image_03 from '../../../assets/img/HomePage/WhyChoose/image_03.webp';
import image_04 from '../../../assets/img/HomePage/WhyChoose/image_04.svg';
import { Fade } from "react-reveal";

const cards1 = [
    {
        title: 'home.why_choose.card_01.title',
        text: 'home.why_choose.card_01.text',
    },
    {
        image: image_02,
        title: 'home.why_choose.card_02.title',
        text: 'home.why_choose.card_02.text',
    },
];

const cards2 = [
    {
        image: image_03,
        title: 'home.why_choose.card_03.title',
        text: 'home.why_choose.card_03.text',
    },
    {
        image: image_04,
        title: 'home.why_choose.card_04.title',
        text: 'home.why_choose.card_04.text',
    },
]

const WhyChoose = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={classes.title}>
                    <div className={`${classes.ttl_01} font-48`}>
                        {t('home.why_choose.title')}
                    </div>
                    <div className={`${classes.ttl_02} font-64`}>
                        Rudiq?
                    </div>
                </h2>
                <div className={classes.cardsWrap}>
                    <div className={`${classes.cards_01} ${classes.cards}`}>
                        {cards1.map((card, index) =>
                            <Fade
                                key={index}
                                left={index === 0 ? true : false}
                                right={index === 1 ? true : false}
                            >
                                <div className={`${classes.card} whychoose_card_0${index + 1}`}>
                                    <h4 className={`${classes.subtitle} font-24`}>
                                        {t(card.title)}
                                    </h4>
                                    <p className={`${classes.text} font-16`}>
                                        {t(card.text)}
                                    </p>
                                    {card.image &&
                                        <img className={classes.image} src={card.image} alt='' />
                                    }
                                </div>
                            </Fade>
                        )}
                    </div>
                    <div className={`${classes.cards_02} ${classes.cards}`}>
                        {cards2.map((card, index) =>
                            <Fade
                                key={index}
                                left={index === 0 ? true : false}
                                right={index === 1 ? true : false}
                            >
                                <div className={`${classes.card} whychoose_card_0${index + 1}`}>
                                    <h4 className={`${classes.subtitle} font-24`}>
                                        {t(card.title)}
                                    </h4>
                                    <p className={`${classes.text} font-16`}>
                                        {t(card.text)}
                                    </p>
                                    {card.image &&
                                        <img className={classes.image} src={card.image} alt='' />
                                    }
                                </div>
                            </Fade>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyChoose;
