import $ from 'jquery';
import './App.scss';
import { useEffect, useState, createContext } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { images } from "./utils/imagesToLoad";
import Router from "./components/GlobalComponents/Router/AppRouter";
import FormPopup from './components/GlobalComponents/FormPopup/FormPopup';

export const PopupContext = createContext(null);

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [popupVisible, setPopupVisible] = useState(false);

  const loadImage = (image) => {
    return new Promise((resolve, reject) => {
      const newImage = new Image();
      newImage.src = image;
      newImage.onload = () => resolve(image);
      newImage.onerror = (err) => reject(err);
    });
  };

  useEffect(() => {
    isLoading && $('body').addClass("ovf-hidden");
    Promise
      .all(images.map((image) => loadImage(image)))
      .then(() => setIsLoading(false))
      .catch((err) => {
        console.log("Failed to load images", err)
        setIsLoading(false);
      });
    // eslint-disable-next-line  
  }, []);

  useEffect(() => {
    !isLoading && $('body').removeClass("ovf-hidden");
    !isLoading && $('#loader').addClass("hide");
  }, [isLoading]);

  return (
    <HelmetProvider>
      <PopupContext.Provider
        value={{
          popupVisible,
          setPopupVisible
        }}
      >
        <BrowserRouter>
          <Router />
          <FormPopup visible={popupVisible} setVisible={setPopupVisible} />
        </BrowserRouter>
      </PopupContext.Provider>
    </HelmetProvider>
  );
};

export default App;
