import HomePage from '../pages/HomePage';
import ServicesPage from '../pages/ServicesPage';
import FaqPage from '../pages/FaqPage';

export const routes = [
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/services', element: ServicesPage },
    { path: '/:lang/faq', element: FaqPage },
];


export const headerRoutes = [
    { path: 'services', text: 'header.services' },
    { path: 'faq', text: 'header.faq' },
];
