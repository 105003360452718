import React from "react";
import classes from './OurGuarantees.module.scss';
import { useTranslation } from "react-i18next";
import icon_01 from '../../../assets/img/HomePage/OurGuarantees/icon_01.svg';
import icon_02 from '../../../assets/img/HomePage/OurGuarantees/icon_02.svg';
import icon_03 from '../../../assets/img/HomePage/OurGuarantees/icon_03.svg';
import icon_04 from '../../../assets/img/HomePage/OurGuarantees/icon_04.svg';
import { Fade } from "react-reveal";

const cards = [
    {
        icon: icon_01,
        text: 'home.our_guarantees.card_01.text',
    },
    {
        icon: icon_02,
        text: 'home.our_guarantees.card_02.text',
    },
    {
        icon: icon_03,
        text: 'home.our_guarantees.card_03.text',
    },
    {
        icon: icon_04,
        text: 'home.our_guarantees.card_04.text',
    },
];

const OurGuarantees = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={classes.title}>
                    <div className={`${classes.ttl_01} font-64`}>
                        Our
                    </div>
                    <div className={`${classes.ttl_02} font-48`}>
                        {t('home.our_guarantees.title')}
                    </div>
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <Fade
                            key={index}
                            left={index === 0 ? true : false}
                            bottom={(index === 1 || index === 2) ? true : false}
                            right={index === 3 ? true : false}
                        >
                            <div className={classes.card}>
                                <img className={classes.icon} src={card.icon} alt='' />
                                <p className={`${classes.text} font-24`}>
                                    {t(card.text)}
                                </p>
                            </div>
                        </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default OurGuarantees;
