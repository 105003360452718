import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/ServicesPage/Banner/Banner";
import Tabs from "../components/ServicesPage/ChooseOption/Tabs";

const ServicesPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Rudiq · Services</title>
        <meta name="title" content="Rudiq Marketing Agency Services | Tailored Marketing Solutions" />
        <meta property="og:title" content="Rudiq Marketing Agency Services | Tailored Marketing Solutions" />
        <meta property="twitter:title" content="Rudiq Marketing Agency Services | Tailored Marketing Solutions" />

        <meta name="description" content="Rudiq’s comprehensive range of marketing services, including digital marketing, branding, content creation, design and development. Tailored strategies to meet your unique business needs." />
        <meta property="og:description" content="Rudiq’s comprehensive range of marketing services, including digital marketing, branding, content creation, design and development. Tailored strategies to meet your unique business needs." />
        <meta property="twitter:description" content="Rudiq’s comprehensive range of marketing services, including digital marketing, branding, content creation, design and development. Tailored strategies to meet your unique business needs." />

        <meta property="og:image" content="preview.webp" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.webp" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Tabs />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default ServicesPage;
