import React, { useContext } from "react";
import classes from "./Button.module.scss";
import { useTranslation } from "react-i18next";
import { PopupContext } from '../../../App';

const Button = ({ children, event, form, dark }) => {
  const { t } = useTranslation();
  const { setPopupVisible } = useContext(PopupContext);

  const openForm = () => setPopupVisible(true);

  return (
    <div
      className={`${classes.button} ${dark ? classes.dark : ''} no-select`}
      onClick={form ? openForm : event}
    >
      {children
        ? children
        : t('btn.join')
      }
    </div>
  );
};

export default Button;
