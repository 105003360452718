import React, { useState } from "react";
import classes from './Tabs.module.scss';
import Buttons from "./Buttons";
import image_01 from '../../../assets/img/ServicesPage/Tabs/image_01.avif'
import image_02 from '../../../assets/img/ServicesPage/Tabs/image_02.avif'
import image_04 from '../../../assets/img/ServicesPage/Tabs/image_04.avif'
import Tab from "./Tab";

const tabs = [
    {
        classes: classes.tab_01,
        button: 'services.tabs.btn_01',
        title: 'services.tabs.tab_01.title',
        text: [
            'services.tabs.tab_01.text_01',
            'services.tabs.tab_01.text_02',
            'services.tabs.tab_01.text_03',
        ],
        desc: 'services.tabs.tab_01.desc',
        image: image_01,
        btn: true,
    },
    {
        classes: classes.tab_02,
        button: 'services.tabs.btn_02',
        title: 'services.tabs.tab_02.title',
        text: [
            'services.tabs.tab_02.text_01',
            'services.tabs.tab_02.text_02',
            'services.tabs.tab_02.text_03',
        ],
        image: image_02,
        btn: true,
    },
    {
        classes: classes.tab_03,
        button: 'services.tabs.btn_03',
        title: 'services.tabs.tab_03.title',
        cards: [
            {
                title: 'services.tabs.tab_03.card_01.title',
                text: 'services.tabs.tab_03.card_01.text',
            },
            {
                title: 'services.tabs.tab_03.card_02.title',
                text: 'services.tabs.tab_03.card_02.text',
            },
            {
                title: 'services.tabs.tab_03.card_03.title',
                text: 'services.tabs.tab_03.card_03.text',
            },
        ],
    },
    {
        classes: classes.tab_04,
        button: 'services.tabs.btn_04',
        title: 'services.tabs.tab_04.title',
        text: [
            'services.tabs.tab_04.text_01',
            'services.tabs.tab_04.text_02',
        ],
        image: image_04,
        btn: true,
    },
];

const Tabs = () => {
    const [active, setActive] = useState(0);
    return (
        <section id="partnershipChooseOption" className="mt container">
            <div className={classes.wrap}>
                <Buttons tabs={tabs} active={active} setActive={setActive} />
                <div className={classes.tabWrap}>
                    <Tab data={tabs[active]} />
                </div>
            </div>
        </section>
    );
};

export default Tabs;
