import React, { useState } from "react";
import classes from './Tabs.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from '../../GlobalComponents/Button/Button';

const Tab = ({ data }) => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);

    const cardClick = (value) => value === active
        ? setActive(-1)
        : setActive(value);

    return (
        <div className={`${classes.tab} ${data.classes}`}>
            <h3 className={`${classes.title} font-48`}>
                <Trans>
                    {t(data.title)}
                </Trans>
            </h3>
            {data.text &&
                <div className={`${classes.text} font-16`}>
                    {data.text.map((txt, index) =>
                        <p key={index}>
                            {t(txt)}
                        </p>
                    )}
                </div>
            }
            {data.desc &&
                <div className={`${classes.desc} font-24-roboto`}>
                    {t(data.desc)}
                </div>
            }
            {data.btn &&
                <div className={classes.btn}>
                    <Button form />
                </div>
            }
            {data.image &&
                <img className={classes.image} src={data.image} alt='' />
            }
            {data.cards &&
                <div className={classes.cards}>
                    {data.cards.map((card, index) =>
                        <div
                            key={index}
                            className={`${classes.card} ${active === index ? classes.active : ''}`}
                        >
                            <div className={classes.head}>
                                <div className={classes.num}>
                                    0{index + 1}
                                </div>
                                <h4 className={`${classes.subtitle} font-24-roboto`}>
                                    {t(card.title)}
                                </h4>
                                <div
                                    className={classes.button}
                                    onClick={() => cardClick(index)}
                                ></div>
                            </div>
                            <p className={`${classes.content} font-16`}>
                                {t(card.text)}
                            </p>
                        </div>
                    )}
                </div>
            }
        </div>
    );
};

export default Tab;
