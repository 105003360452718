import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/HomePage/Banner/Banner";
import Underbanner from "../components/HomePage/Underbanner/Underbanner";
import WhyChoose from "../components/HomePage/WhyChoose/WhyChoose";
import OurGuarantees from "../components/HomePage/OurGuarantees/OurGuarantees";
import StartNow from "../components/HomePage/StartNow/StartNow";

const HomePage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Rudiq</title>
        <meta name="title" content="Rudiq Marketing Agency | Innovative Marketing Solutions" />
        <meta property="og:title" content="Rudiq Marketing Agency | Innovative Marketing Solutions" />
        <meta property="twitter:title" content="Rudiq Marketing Agency | Innovative Marketing Solutions" />

        <meta name="description" content="Discover Rudiq, where we turn your vision into reality with innovative marketing strategies that drive results. Connect with your target audience and elevate your brand today." />
        <meta property="og:description" content="Discover Rudiq, where we turn your vision into reality with innovative marketing strategies that drive results. Connect with your target audience and elevate your brand today." />
        <meta property="twitter:description" content="Discover Rudiq, where we turn your vision into reality with innovative marketing strategies that drive results. Connect with your target audience and elevate your brand today." />

        <meta property="og:image" content="preview.webp" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.webp" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Underbanner />
        <WhyChoose />
        <OurGuarantees />
        <StartNow />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default HomePage;
