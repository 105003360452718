import React from "react";
import classes from "./Footer.module.scss";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="mt container font-20">
      <div className={classes.footer}>
        {t('footer.rights')}
      </div>
    </footer>
  );
};

export default Footer;
