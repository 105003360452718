import React from "react";
import classes from './Tabs.module.scss';
import { useTranslation } from "react-i18next";

const Buttons = ({ tabs, active, setActive }) => {
    const { t } = useTranslation();
    return (
        <div className={classes.buttons}>
            {tabs.map((tab, index) =>
                <div
                    key={index}
                    className={`${classes.button} ${active === index ? classes.active : ''} font-20 no-select`}
                    onClick={() => setActive(index)}
                >
                    <p>
                        {t(tab.button)}
                    </p>
                    <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#2A2A2A" />
                        <path d="M5 12H19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M12 5L19 12L12 19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            )}
        </div>
    );
};

export default Buttons;